import React, { useState } from 'react'
import moment from 'moment'
import { navigate } from 'gatsby'

import styles from './rent-item.module.scss'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'
import BillingInfoSelector from 'components/BillingInfoSelector/BillingInfoSelector'
import DatabaseService from 'services/DatabaseService'
import { getRoute } from 'utils/index'
import useAsyncEffect from 'utils/hooks/useAsyncEffect'
import UserStateStore from 'state/UserStateStore'
import { observer } from 'mobx-react-lite'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

function RentalDisplay(props) {
  const {
    rental,
    onSubmit,
    submitting,
    onConfirmation,
    disabled = false,
  } = props

  if (!rental) {
    return null
  }

  const {
    item,
    start_date,
    end_date,
    total_price_nofee,
    total_price,
    service_fee,
    price_d,
    num_days,
  } = rental

  return (
    <div className={styles.rentalDisplayContainer}>
      <div className={styles.image}>
        <img className={styles.img} src={item.pictures[0]} />
      </div>
      <form
        onSubmit={e => (e.preventDefault(), onSubmit())}
        className={styles.description}
      >
        <h3>{item.name}</h3>
        <div>
          <span>From</span>
          <b>{moment(start_date).format('dddd, MMMM Do YYYY')}</b>
        </div>
        <div>
          <span>Until</span>
          <b>{moment(end_date).format('dddd, MMMM Do YYYY')}</b>
        </div>
        <div>
          <span>Duration</span>
          <b>
            {num_days} Day{num_days > 1 ? 's' : ''}
          </b>
        </div>
        <div>
          <span>Daily Cost</span>
          <b>{formatter.format(price_d / 100)}</b>
        </div>
        <div>
          <span>Subtotal</span>
          <b>{formatter.format(total_price_nofee)}</b>
        </div>
        <div>
          <span>Service Fee</span>
          <b>{formatter.format(service_fee)}</b>
        </div>
        <div style={{ fontSize: '1.2em' }}>
          <b>TOTAL</b>
          <b>{formatter.format(total_price)}</b>
        </div>
        <small>
          NOTE: You will not be charged for the rental until it is accepted by
          the lender.
        </small>
        <small>
          By confirming this rental you are acknowledging that you agree to
          Mellow's
          <a
            target="blank"
            href="https://help.borrowmellow.com/category/15-cancellation-policies"
          >
            &nbsp;Cancellation Policy
          </a>
          ,
          <a target="blank" href="/legal/terms">
            &nbsp;Terms or Service
          </a>
          , and
          <a target="blank" href="/legal/privacy">
            &nbsp;Privacy Policy
          </a>
          .
        </small>
        <button className={styles.confirmationButton} disabled={disabled}>
          {submitting ? 'Please Wait' : 'Confirm Rental'}
        </button>
      </form>
    </div>
  )
}

function RentItemPage(props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [billingSource, setBillingSource] = useState(null)
  const [error, setError] = useState(false)

  const rental = props.location.state.rental
  const onSubmit = async () => {
    setIsSubmitting(true)
    try {
      const result = await DatabaseService.createRental({
        source: billingSource,
        itemId: rental.item.id,
        start_date: rental.start_date,
        end_date: rental.end_date,
      })
      navigate('/rent-item/success')
    } catch (error) {
      setError(true)
      setIsSubmitting(false)
    }
  }
  return (
    <div
      className={[styles.container, isSubmitting ? styles.submitting : ''].join(
        ' '
      )}
    >
      <div className={styles.billingInfo}>
        <h3>Select Payment Method</h3>
        <BillingInfoSelector
          submitting={isSubmitting}
          onChangeSelection={source => setBillingSource(source)}
        />
      </div>
      <div className={styles.rentalDisplay}>
        <RentalDisplay
          type="lend"
          onSubmit={onSubmit}
          submitting={isSubmitting}
          disabled={billingSource === null}
          rental={rental}
        />
      </div>
    </div>
  )
}

export default observer(({ location }) => {
  // when the page loads, asynchronously ping the backend
  // so that cold starts don't take as long

  // when the page loads, asynchronously ping the backend
  // so that cold starts don't take as long
  useAsyncEffect(async () => {
    if (!UserStateStore.user) {
      return
    }
    await DatabaseService.ping()
  }, [UserStateStore.user])

  return (
    <Layout location={location} showBeacon={true} displayFooter={false}>
      <SEO title="Rental" />
      <RentItemPage location={location} />
    </Layout>
  )
})

import React from 'react'

import styles from './FancyInput.module.scss'

export default function FancyInput(props) {
  const {
    label,
    name,
    value = '',
    error,
    touched,
    disabled = false,
    ...inputProps
  } = props
  return (
    <div
      className={[
        styles.container,
        disabled ? styles.disabled : '',
        // error ? styles.error : ''
      ].join(' ')}
    >
      <label
        htmlFor={name}
        className={[styles.label, value.length ? styles.touched : ''].join(' ')}
      >
        {label}
      </label>
      <input
        {...inputProps}
        type={'text'}
        name={name}
        value={value}
        disabled={disabled}
        className={[styles.input, value.length ? styles.touched : ''].join(' ')}
      />
      {/* <div className={styles.controls}>
        <div className={styles.resetField}></div>
      </div> */}
    </div>
  )
}

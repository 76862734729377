import React, { useState, useMemo, useEffect } from 'react'
import {
  StripeProvider,
  injectStripe,
  CardElement,
  Elements,
} from 'react-stripe-elements'
import { Formik } from 'formik'
import * as yup from 'yup'
import { observer } from 'mobx-react-lite'
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcJcb,
  faCcDinersClub,
} from '@fortawesome/free-brands-svg-icons'
import ErrorBoundary from 'react-error-boundary'

import styles from './Billing.module.scss'
import UserStateStore from 'state/UserStateStore'
import FancyInput from 'components/FancyFormElements/FancyInput'
import keys from 'constants/keys'
import DatabaseService from 'services/DatabaseService'

function BillingInfo(props) {
  const { onSelect, source = {}, checked = false } = props
  const getCCIcon = brand => {
    brand = brand.toLowerCase()
    return (
      {
        visa: faCcVisa,
        mastercard: faCcMastercard,
        'american express': faCcAmex,
        discover: faCcDiscover,
        jcb: faCcJcb,
        'diners club': faCcDinersClub,
      }[brand] || faCreditCard
    )
  }
  const {
    owner: { name } = {},
    card: { brand, exp_month, exp_year, last4 } = {},
  } = source
  return (
    <div className={styles.billingInfo}>
      <div className={styles.label}>
        <label className={styles.cardInfoLabel}>
          <input
            type="radio"
            name="payment"
            onChange={e => onSelect(source)}
            defaultChecked={checked}
          />
          <div>
            <FaIcon icon={getCCIcon(brand)} style={{ fontSize: 22 }} />
          </div>
          <div>{name}</div>
          <div>****{last4}</div>
          <div>
            {exp_month}/{exp_year.toString().substr(-2)}
          </div>
        </label>
      </div>
    </div>
  )
}

const BillingInfoCreator = injectStripe(props => {
  const { open, stripe } = props
  const [isOpen, setOpen] = useState(open)
  const [isCardElementComplete, setIsCardElementComplete] = useState(false)
  return (
    <div className={styles.billingInfo}>
      <div className={styles.label}>
        <label>Add New Payment Method</label>
      </div>
      <div className={styles.dropdown}>
        <Formik
          initialValues={{
            cardholderName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
          }}
          validationSchema={yup.object({
            cardholderName: yup.string().required(),
            address1: yup.string().required(),
            city: yup.string().required(),
            state: yup.string().required(),
          })}
          onSubmit={async (values, { setFieldError, setSubmitting }) => {
            if (!isCardElementComplete) {
              // show an error for card element
              setFieldError('cardElement', 'Incomplete')
              setSubmitting(false)
              return
            }
            const {
              cardholderName,
              address1: line1,
              address2: line2,
              city,
              state,
              zip: postal_code,
            } = values
            try {
              const { source, error = null } = await stripe.createSource({
                type: 'card',
                usage: 'reusable',
                currency: 'USD',
                owner: {
                  name: cardholderName,
                  address: {
                    line1,
                    line2,
                    city,
                    state,
                    postal_code,
                  },
                },
              })
              if (error) {
                setFieldError('cardElement', result.error)
                setSubmitting(false)
                return
              }
              await DatabaseService.addUserPaymentSource(source)
              // close and reset the form
              setSubmitting(false)
            } catch (e) {
              console.log('error')
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <FancyInput
                value={values.cardholderName}
                touched={touched.cardholderName}
                error={errors.cardholderName}
                onFocus={() => setFieldTouched('cardholderName')}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                name={'cardholderName'}
                label={'Cardholder Name'}
              />
              <FancyInput
                value={values.address1}
                touched={touched.address1}
                error={errors.address1}
                onFocus={() => setFieldTouched('address1')}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                name={'address1'}
                label={'Address 1'}
              />
              <FancyInput
                value={values.address2}
                touched={touched.address2}
                error={errors.address2}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => setFieldTouched('address2')}
                disabled={isSubmitting}
                name={'address2'}
                label={'Address 2 (optional)'}
              />
              <FancyInput
                value={values.city}
                touched={touched.city}
                error={errors.city}
                onFocus={() => setFieldTouched('city')}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                name={'city'}
                label={'City'}
              />
              <div className={styles.formRow}>
                <FancyInput
                  value={values.state}
                  touched={touched.state}
                  error={errors.state}
                  onFocus={() => setFieldTouched('state')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  name={`state`}
                  label={'State'}
                />
                <FancyInput
                  value={values.zip}
                  touched={touched.zip}
                  error={errors.zip}
                  onFocus={() => setFieldTouched('zip')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  name={'zip'}
                  label={'Zip Code'}
                />
              </div>
              <CardElement
                hidePostalCode={true}
                disabled={isSubmitting}
                className={styles.cardElement}
                onChange={e =>
                  e.complete != isCardElementComplete &&
                  setIsCardElementComplete(e.complete)
                }
                style={{
                  base: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    '::placeholder': {
                      fontWeight: 'normal',
                      fontSize: '14px',
                    },
                  },
                }}
              />
              {/* <small>All payment processing is handled via Stripe</small> */}
              <button
                type="submit"
                className={[
                  styles.submit,
                  isSubmitting ? styles.busy : '',
                ].join(' ')}
                disabled={isSubmitting}
              >
                Add Payment Info
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
})

export default observer(props => {
  const {
    selected = 0,
    onChangeSelection,
    className = '',
    ...containerProps
  } = props

  const stripe = useMemo(() => {
    if (typeof window == 'undefined') {
      return null
    }
    return window.Stripe(keys.STRIPE.PUBLISHABLE_KEY)
  }, [])

  const user = UserStateStore.user

  return (
    <div
      className={[styles.container, className].join(' ')}
      {...containerProps}
    >
      {user &&
        user.paymentSources &&
        user.paymentSources.map((source, i) => {
          return (
            <ErrorBoundary key={`source-${i}`} FallbackComponent={() => null}>
              <BillingInfo source={source} onSelect={onChangeSelection} />
            </ErrorBoundary>
          )
        })}
      <StripeProvider stripe={stripe}>
        <Elements>
          <BillingInfoCreator
            open={!user || !user.billingInfo || !user.billingInfo.length}
          />
        </Elements>
      </StripeProvider>
    </div>
  )
})
